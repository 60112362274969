var searchKeys = [{
  key: "nickname",
  label: "鉴定师",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "year",
  label: "账单日期",
  placeholder: "请选择年月",
  required: false,
  rules: [],
  cascader: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var detailsearchKeys = [{
  key: "orderno",
  label: "订单编号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, detailsearchKeys };